.countdown-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .countdown-text {
    font-size: 24px;
    margin-bottom: 6px;
  }

  .countdown-text2 {
    margin-top: 0vh;
    font-weight: normal;
    font-size: 18px;
  }
  
  .countdown-timer {
    display: flex;
    justify-content: center;
  }
  
  .countdown-item {
    margin: 0 10px;
  }
  
  .countdown-item h1 {
    font-size: 48px;
    margin: auto;
  }
  
  .countdown-item p {
    font-size: 16px;
    margin-top: 5px;
  }
  