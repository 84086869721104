.container{
  display: flex;
  padding-top: 50px;
  padding-right: 12px;
  padding-bottom: 5px;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  background-image: linear-gradient(to top, rgba(0, 0, 0, .5) 10%, transparent);
}

.section{
  color: rgba(255, 255, 255, .5);
  opacity: .8;
  transition: .3s;
}

.section:hover{
  opacity: 1;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.splitter{
  color: rgba(255, 255, 255, .5);
  margin-left: 5px;
  margin-right: 5px;
  opacity: .8;
}

@media (orientation:portrait){
  .container{
    width: 100vw;
    padding-bottom: 4px;
    /* background-color: #fff; */
  }
}