.discordBtn{
  transition: .5s ease-in-out; 
  background-color: transparent;
}

.discordBtn:hover{
  background-color: var(--light-blue);
}

.btnMobile{
  background:    linear-gradient(#ffbf00, #bd4805);
  border-radius: 1000px;
  box-shadow:    0 3px #444444;
  padding:       12px 28px;
  color:         #ffffff;
  display:       inline-block;
  font-size:     15px;
  font-family:   'Fugaz One';
  text-align:    center;
  text-shadow:   1px 1px #000000;
}

.btn{

  font-family: 'Fugaz One';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  background: linear-gradient(0deg, #9d0000 -70%, #ffae00 100%);
  padding: 10px 30px;
  border: solid #bd8400 2px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 50px;
  transition : 468ms;
  transform: translateY(0);
  display: flex;
  z-index: 1000;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  }
  
.btn:hover{

  transition : 468ms;
  padding: 10px 50px;
  transform : translateY(-0px);
  background: linear-gradient(0deg, #d49400 0%, #ffb300 100%);
  color: #000000;
  border: solid 2px #000000;
  }

  .btnReadMore{
  font-family: 'Fugaz One';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  background-color: #ffb300;
  padding: 10px 60px;
  border: solid #bd8400 2px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 2px 32px 26px 50px;
  transition : 468ms;
  transform: translateY(0);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  }
  
  .btnReadMore:hover{
  
  transition : 468ms;
  padding: 10px 50px;
  transform : translateY(-0px);
  background-color: #ffb300;
  color: #000000;
  border: solid 2px #000000;
  }
  

.arrowDown1{
  animation: arrowAnim1 2s infinite;
  animation-delay: 0s;
}
.arrowDown2{
  animation: arrowAnim2 2s infinite;
  animation-delay: .25s;
}
.arrowDown3{
  animation: arrowAnim3 2s infinite;
  animation-delay: .5s;
}

@keyframes arrowAnim1{
  0%, 100%{
    opacity: 1;
  }50%{
    opacity: 0;
  }
}
@keyframes arrowAnim2{
  0%, 100%{
    opacity: 1;
  }50%{
    opacity: 0;
  }
}
@keyframes arrowAnim3{
  0%, 100%{
    opacity: 1;
  }50%{
    opacity: 0;
  }
}



.meteor1, .meteor2, .meteor3, .meteor4 {
  animation: float 1.5s infinite ease-in-out alternate;
}

.meteor1 {
  animation-delay: 0.2s;
}

.meteor2 {
  animation-delay: 0.6s;
}

.meteor3 {
  animation-delay: 1s;
}

.meteor4 {
  animation-delay: 0.8s;
}

@keyframes float {
  100% {
    transform: translateY(20px);
  }
}

.bar1, .bar2, .bar3, .bar4, .bar5, .bar6, .bar7 {
  animation: float2 1.5s infinite ease-in-out alternate;
  }
  
  .bar1 {
  animation-delay: 0.2s;
  }
  
  .bar2 {
  animation-delay: 0.6s;
  }
  
  .bar3 {
  animation-delay: 1s;
  }
  
  .bar4 {
  animation-delay: 0.8s;
  }
  
  .bar5 {
  animation-delay: 1.2s;
  }
  
  .bar6 {
  animation-delay: 1.4s;
  }
  
  .bar7 {
  animation-delay: 1.6s;
  }
  
  @keyframes float2 {
  100% {
  transform: translateY(-5px);
  opacity: 40%;
  }
  }

